import React        from "react";
import Layout       from "../components/Layout";
import StyledHero   from "../components/StyledHero";
import { graphql }  from "gatsby";
import PhotoList    from "../components/photos/PhotoList"



export const query = graphql`
  {
    blogBcg: file(relativePath: {eq: "hampiPhoto.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;


const Photos = ( { data }) => {
    
    return(
        <Layout>
            <StyledHero img={data.blogBcg.childImageSharp.fluid} />
            <PhotoList />
        </Layout>
    )
}

export default Photos