import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as styles from "../../css/place.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";



const PhotoCard = ({ photo }) => {
    const { name , slug , images} = photo
    console.log(images)
    // so images 0 returns the first node object
    let mainImage = getImage(images[0])
     return (
        <article className={styles.place}>
            <div className={styles.imgContainer}>
                <GatsbyImage image={mainImage} className={styles.img} alt="single photo" />
                <AniLink fade to={`/photos/${slug}`} className={styles.link}>
                    open
                </AniLink>
            </div>
            <div className={styles.footer}>
                <h3>{ name }</h3>
            </div>
        </article>
    )
}

export default PhotoCard