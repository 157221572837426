import React from 'react'
// now i need to add the query , that is what i ahve to do , '
import { useStaticQuery, graphql } from 'gatsby'
import Title from "../Title"
import * as styles from "../../css/items.module.css"
import PhotoCard from './PhotoCard'

// i wont export it i will store it in the the getPhotos
 const getPhotos = graphql`
  {
    photos: allContentfulPhotos {
      edges {
        node {
          id
          name
          slug
          description
          images {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`
const PhotoList = () => {
   const { photos } = useStaticQuery(getPhotos)
    console.log(photos)
    // i ll have to use places instead of tours
    return(
        <section className={styles.places}> 
            <Title title="hampi" subtitle="photos"/>
            <div className={styles.center}>
                { photos.edges.map(({ node }) => 
                {
                    return <PhotoCard key={node.id} photo={node}/>
                })}
            </div>
        </section>
    )
}

export default PhotoList